import React, { useState } from 'react';
import classnames from 'classnames';
import { string } from 'prop-types';
import { Link } from 'gatsby';
import root from 'window-or-global';
import loadable from '@loadable/component';
import { SIGNUP_REDIRECT_URL } from 'apputil/auth-0';

import { useAuth0 } from '@auth0/auth0-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/pro-solid-svg-icons';
import { faSmilePlus, faDirections } from '@fortawesome/pro-light-svg-icons';

import { LOGIN_ROUTE } from 'constants/navigation';

import useStyles from './styles';

const Button = loadable(() => import('@material-ui/core/Button'));
const Paper = loadable(() => import('@material-ui/core/Paper'));
const Grid = loadable(() => import('@material-ui/core/Grid'));
const Typography = loadable(() => import('@material-ui/core/Typography'));
const Hidden = loadable(() => import('@material-ui/core/Hidden'));

const RegistrationTryForFree = loadable(() => import('views/registration/try-free-section'));
const LazyLandingPricesSelect = loadable(() => import('components/landing-prices/select'));
const RegistrationIconList = loadable(() => import('components/icon-list/registration'));

const StartUpBenefitsInfo = loadable(() => import('views/registration/start-up-benefits'));
const BronzeBenefitsInfo = loadable(() => import('views/registration/bronze-benefits'));
const SilverBenefitsInfo = loadable(() => import('views/registration/silver-benefits'));
const GoldBenefitsInfo = loadable(() => import('views/registration/gold-benefits'));

const RegistrationHomeView = ({ sub = '' }) => {
  const styles = useStyles();
  const [working, setWorking] = useState(false);
  const [subscription, setsubscription] = useState(sub);

  const { loginWithRedirect } = useAuth0();

  const getBenefits = () => {
    if (subscription === 'start') {
      return <StartUpBenefitsInfo />;
    }

    if (subscription === 'bronze') {
      return <BronzeBenefitsInfo/>;
    }

    if (subscription === 'silver') {
      return <SilverBenefitsInfo/>;
    }

    return <GoldBenefitsInfo/>;
  };

  return (
    <>
    <div className={styles.registrationContent}>
      <RegistrationIconList/>
    </div>
    <div className={styles.content}>
      <Grid spacing={2}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
        <Grid item sm={12} md={10} lg={10} xl={8} className={styles.twoBotMargin}>
          <Paper elevation={4}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              >
                <Grid item lg={8} md={10} sm={10} xs={12}>
                  <Typography
                    variant="h4"
                    component="h2"
                    className={classnames(styles.headerGreen, styles.fourTopBotMargin)}
                    align="center">
                    Create an Account
                    <FontAwesomeIcon icon={faSmilePlus} size="lg" className={styles.headerIcon}/>
                    <Typography
                      variant="caption"
                      component="p"
                      align="right"
                      className={styles.headerSubText}>
                      No contract, buy, change or cancel your subscription at any time
                    </Typography>
                  </Typography>
                </Grid>

                <Grid item sm={10} xs={12}>
                  <Hidden xsDown>
                    <Typography
                      variant="h6"
                      component="p"
                      align="center"
                      className={classnames(styles.bodyNoBottomMargin, styles.fourBotMargin)}>
                      {'We\'ve tried to make your journey with Total Loyalty is easy as possible.'}
                    </Typography>
                  </Hidden>
                  <Hidden smUp>
                    <Typography
                      variant="h6"
                      component="p"
                      align="center"
                      className={classnames(styles.bodyNoBottomMargin, styles.fourBotMargin)}>
                      {'We\'ve tried to make your journey with'} <br/> {'Total Loyalty is easy as possible.'}
                    </Typography>
                  </Hidden>
                </Grid>

                <Grid item lg={8} md={10} sm={10} xs={12}>
                  <Typography
                    variant="subtitle1"
                    component="p"
                    align="justify"
                    className={classnames(styles.bodyNoBottomMargin, styles.fourBotMargin)}>
                    {`Click the "Get Started" button, use your Google or Apple account, or an email and password to create your login.
                    If our software is right for your buisiness choose the subcription that suits your needs. There's no contract, you can cancel or change
                    your subscription at any time.`}
                  </Typography>
                </Grid>

                <Grid item xs={10} sm={10} md={8} lg={8} className={styles.noMargin}>
                {working ? (
                  <Typography
                    variant="h4"
                    component="p"
                    align="center"
                    className={styles.redirectText}>
                     Redirecting you now...
                     <FontAwesomeIcon icon={faDirections} size="lg" className={styles.redirectIcon}/>
                  </Typography>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    className={styles.greenformbutton}
                    disabled={working}
                    aria-label="start registration"
                    onClick={() => {
                      setWorking(true);
                      setTimeout(() => loginWithRedirect({
                        prompt: 'select_account',
                        screen_hint: 'signup',
                        redirectUri: SIGNUP_REDIRECT_URL,
                      }), 100);
                    }}
                    fullWidth
                    >
                   Get Started
                   <FontAwesomeIcon icon={faUserPlus} className={styles.submitIcon}/>
                 </Button>
                )}
              </Grid>
              <Grid item xs={12} sm={10} className={styles.divider}/>
               {!working && (<Grid item xs={10} sm={10} md={8}>
                <Link to={LOGIN_ROUTE}>
                  <Typography
                    variant="subtitle1"
                    component="p"
                    color="primary"
                    align="right"
                    className={styles.loginLink}>
                    Already have an account
                  </Typography>
                </Link>
              </Grid>
               )}
            </Grid>
          </Paper>
        </Grid>

        <Grid item sm={12} md={10} lg={10} xl={8}>
          <RegistrationTryForFree />
        </Grid>

        <Grid item sm={12} md={10} lg={10} xl={8}>
          <span id="benefits" />
          <LazyLandingPricesSelect onSelect={(val) => {
            setsubscription(val);
            setTimeout(() => {
              const elmnt = root.document.getElementById('benefits');
              if (elmnt) {
                elmnt.scrollIntoView({ behavior: 'smooth', inline: 'start' });
              }
            }, 100);
          }}
          subscription={subscription}/>
        </Grid>

        <Grid item sm={12} md={10} lg={10} xl={8} className={styles.fourBotMargin}>
          <Paper elevation={4}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              >
                {getBenefits()}
              </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  </>
  );
};

RegistrationHomeView.propTypes = {
  sub: string,
};

export default RegistrationHomeView;
